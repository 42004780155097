import React from 'react'
import { useEffect } from 'react';

const AdvertComponent = ({ height, width }) => {
    useEffect(()=>{
        (window.adsbygoogle = window.adsbygoogle || []).push({});    
    },[])
  return (
    <div className='advertComponent' style={{
      height: height,
      width: width
    }}>
        <ins className='adsbygoogle'
        style={{ display: 'block' }}
        data-ad-client='ca-pub-7901516692792431'
        data-ad-slot='9174151667'
        data-ad-format='auto'
        data-full-width-responsive='true'></ins>
    </div>
  )
}

export default AdvertComponent

