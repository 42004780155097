import "./App.css";
import AssemblyTable from "./AssemblyTable";
import Header from "./Header";
import SvgHand from './icon_components/Hand';
import SvgCurrentSource from './icon_components/CurrentSource';
import SvgSolve from './icon_components/Solve';
import SvgWire from './icon_components/Wire';
import SvgVoltageSource from "./icon_components/VoltageSource";
import SvgRotate from "./icon_components/Rotate";
import SvgResistor from "./icon_components/Resistor";
import SvgRemove from "./icon_components/Remove";
import SvgLoad from "./icon_components/Load";
import Node from './Node';
import { useState, useEffect, useRef } from "react";
import breadboard from "./ecc-library";
import NavBar from "./NavBar";


const dashboardIcons = [
  // <SvgVoltageSource />,
  // <SvgCurrentSource />,
  <SvgResistor />,
  <SvgWire />,
  <SvgLoad />,
  // "",
  // <SvgHand />,
  // <SvgRotate />,
  <SvgRemove />,
  <SvgSolve />,
]

const iconId = [
  // "V",
  // "I",
  "R",
  "L",
  "X",
  // "_blank", //potentially "selectAll"
  // "_move",
  // "_rotate",
  "_remove",
  "_solve"
]
const iconMap = new Map();

  for (let i = 0; i < iconId.length; i++) {
    iconMap.set(iconId[i], dashboardIcons[i]);
  }


function App() {

  let [nodeMap,setNodeMap] = useState(new Map()); 
  let [nodeCounter,setNodeCounter] = useState(-1); 
  let [nodePair, setNodePair] = useState([]);  
  let [componentsOnBreadboard, setComponents] = useState([]); 
  let [numOfBreadBoardComponents, setonBreadBoardComponent] = useState(new Map());
  let [componentNodeMap, setComponentNodeMap] = useState(new Map());
  let [selectedGraphComponentName, setName] = useState("Select");
  let [selectedGraphComponentValue, setValue] = useState(0);
  let [numNodeConnected, setNumNodeConnected] = useState(new Map());

  let [signedIn, setSignedIn] = useState(false);
  let [logInPageOpen, setLogInPage] = useState(false);
  let [userAccount, setUserAccount] = useState({ username: '', email: '', password: '' })
  let [currAnswer, setCurrAnswer] = useState(0);


  if (numOfBreadBoardComponents.size === 0) {
    for (let component of iconId) {
      if (component[0] !== '_') {
        numOfBreadBoardComponents.set(component, 0);
      }
    }
  }
  
  const atLeastOneComponent = () =>{
    for (let anyComponentId of iconId) {
      if (isSelected(anyComponentId)) {
        return true;
      }
    }
    return false;
  }

 const getSelectedComponent = () => {
    for (let componentId of iconId) {
      let elem = document.getElementById(componentId);
      if (elem.classList.contains("selected")) {
        return componentId;
      }
    }
  }
  
  const incrementComponentCount = (componentTypeName) =>{
    if (numOfBreadBoardComponents.has(componentTypeName)) {
      let initialCount = numOfBreadBoardComponents.get(componentTypeName);
      numOfBreadBoardComponents.set(componentTypeName, initialCount + 1);
    } else {
     numOfBreadBoardComponents.set(componentTypeName, 1);
    }
    setonBreadBoardComponent(numOfBreadBoardComponents);
  }

  const select = (id) => {

 
  if (id[0] === 'n' && atLeastOneComponent()) {
    let componentTypeName = getSelectedComponent();
    if (componentTypeName[0] === '_') {
      return;
    }
      nodePair.push(id);
      selectedNode(id,true);
    
    if (nodePair.length === 2) {
      if (nodeMap.has(nodePair[0])) {
      } else {
        nodeMap.set(nodePair[0], nodeCounter + 1);
        nodeCounter += 1;
      }
      if (nodeMap.has(nodePair[1])) {
      } else {
        
        nodeMap.set(nodePair[1], nodeCounter + 1);
        nodeCounter += 1;
      }
      setNodeCounter(nodeCounter); 
      setNodeMap(()=>nodeMap); 
      
     
      
     

      let node1_id = nodePair[0];
      let node2_id = nodePair[1];
      
      insertComponent(componentTypeName, node1_id, node2_id);
      incrementComponentCount(componentTypeName); 
      let [componentTypeId, componentStyle, currComponentId] = componentsOnBreadboard[componentsOnBreadboard.length - 1]; 
      componentNodeMap.set(currComponentId, [nodeMap.get(nodePair[0]), nodeMap.get(nodePair[1])]);
      setComponentNodeMap(() => componentNodeMap);
      

      let addedComponentId = componentTypeName + "" + numOfBreadBoardComponents.get(componentTypeName);
      setNodePair(()=>nodePair = []);
       
    }
        

      return;
  }
  for (let componentId of iconId) {
    if (id[0] === componentId[0]) {
      selectedComponent(id);
      return;
    }
  }
  }


  useEffect(() => {
    window.addEventListener("resize",  setRefNode(()=> document.getElementById("n_0"))); 
  });
  

  let nodes=[];
  for (let i = 0; i < 400; i++) {
  let id = "n_"+i;
    nodes.push(<Node key={id} id={id} onSelect={select} />);
  }
  const [refNode,setRefNode] = useState(document.getElementById('n_0')); 

 
  const insertComponent = (componentTypeIconid, node1_id, node2_id) => {

    let currComponentId = `${componentTypeIconid}${numOfBreadBoardComponents.get(componentTypeIconid) + 1}`;
    let componentValue = 0;
   

    let nodeElem1 = document.getElementById(node1_id);
    let nodeElem2 = document.getElementById(node2_id);


    
    let [hypotenuse, theta, xOffset, yOffset, xOffsetMax,yOffsetMax] = getHypotenuse(node1_id, node2_id);
    let midPoint = hypotenuse / 2;
    let midPointXOffset = midPoint * (Math.cos(theta * Math.PI / 180));
    let midPointYOffset = midPoint * (Math.sin(theta*Math.PI/180));
    let referenceNodeX = 20-refNode.getBoundingClientRect().x ; 
    let referenceNodeY = 15-refNode.getBoundingClientRect().y;
    let xCoordinate = referenceNodeX - 35 + (xOffset + midPointXOffset);
    let yCoordinate = referenceNodeY - 35 + (yOffset + midPointYOffset);

    let componentStyle = {
      position: 'absolute',
      transformOrigin: 'center center',
      transform: `rotate(${180+theta}deg)`,
      left:`${xCoordinate}px`,
      top:`${yCoordinate}px`,
      color: 'black',
      margin: 0,
     
      width: '70px',
      height: '70px',
    }

    let wireThickness = 3;
    let breadBoardX = document.getElementById('breadBoard').getBoundingClientRect().x;
    let breadBoardY = document.getElementById('breadBoard').getBoundingClientRect().y;
    let wireXCord =0 + 10 + xOffset-breadBoardX;
    let wireYCord = 0 + 5- wireThickness / 2  + yOffset-breadBoardY ;

    let componentWireStyle = {
      position: 'absolute',
      transformOrigin: 'left center',
      transform: `rotate(${theta}deg)`,
      left:`${wireXCord}px`,
      top:`${wireYCord}px`,
      color: 'black',
      margin: 0,
      backgroundColor:'black',
      width: `${hypotenuse}px`,
      height: `${wireThickness}px`,
      
    }

    let componentLeftWireStyle = {
      position: 'absolute',
      transformOrigin: 'left center',
      transform: `rotate(${theta}deg)`,
      left:`${wireXCord-0.5}px`,
      top:`${wireYCord+0.25}px`,
      color: 'black',
      margin: 0,
      backgroundColor:'black',
      width: `${hypotenuse/2-20}px`,
      height: `${wireThickness}px`,
      
    }

    let componentRightWireStyle = {
      position: 'absolute',
      transformOrigin: 'left center',
      transform: `rotate(${180+theta}deg)`,
      left:`${0+10+ xOffsetMax-breadBoardX}px`,
      top:`${0+5- wireThickness / 2  + yOffsetMax-breadBoardY+0.25}px`,
      color: 'black',
      margin: 0,
      backgroundColor:'black',
      width: `${hypotenuse/2-20}px`,
      height: `${wireThickness}px`,
      
    }
    componentsOnBreadboard.push([componentTypeIconid, componentStyle, currComponentId, componentWireStyle,componentLeftWireStyle,componentRightWireStyle, componentValue]); // returns general id of the component type (resistor, voltage source etc) and the style for the current component. Used in Graph.js
    setComponents(()=>componentsOnBreadboard);
  }

  
  const getHypotenuse = (node1_id, node2_id) => {
    let pos1 = document.getElementById(node1_id).getBoundingClientRect();
    let pos2 = document.getElementById(node2_id).getBoundingClientRect();
    let deltaX = pos2.x - pos1.x;
    let deltaY = pos2.y - pos1.y;
       
    let hypotenuse = Math.sqrt(Math.pow(deltaX, 2) + Math.pow(deltaY, 2));
    let initialTheta = (Math.atan(deltaY / deltaX) / (Math.PI / 180));
    let theta;
    theta = initialTheta;
    if (theta <= 0.1) {
      if (pos1.x > pos1.x) {
        theta = 180+theta;
      }
    }
   
    let xOffsetMax;
    let yOffsetMax;
    let xOffset = Math.min(pos1.x, pos2.x);
    let yOffset;
    if (xOffset === pos1.x) {
      yOffset = pos1.y;
      xOffsetMax = pos2.x;
      yOffsetMax = pos2.y;
    }
    else {
      yOffset = pos2.y;
      xOffsetMax = pos1.x;
      yOffsetMax = pos1.y;
    }

    
    return [hypotenuse, theta, xOffset,yOffset, xOffsetMax, yOffsetMax];
   }


  const selectedNode = (id , selecting) => {
    

    if (selecting) {
      if (numNodeConnected.has(id)) {
        numNodeConnected.set(id, numNodeConnected.get(id) + 1);
        setNumNodeConnected(() => numNodeConnected);
      } else {
        numNodeConnected.set(id, 1); 
        setNumNodeConnected(() => numNodeConnected);
      }
    }
    

    if (numNodeConnected.get(id) > 1) {
      if(!isConnected(id))
        addClass(id, "connected"); 
    }
    else if (numNodeConnected.get(id) > 0) {
      if(!isSelected(id))
        addClass(id, "selected");
      removeClass(id, "connected");
    } else {
      console.log("removing");
      removeClass(id, "connected");
      removeClass(id, "selected");
    }

    }
  

  const selectedComponent = (id) => {
      
       let selectedAdded = toggleClass(id, "selected");

        for (let chosen_id of iconId) { 
          if (isSelected(chosen_id) && chosen_id !== id) {
            toggleClass(chosen_id,"selected")
          }
        }
        for (let eachNode of nodes) {
          let currNode = document.getElementById(eachNode.key);
          if (currNode.classList.contains("enabled")) 
            break;
          currNode.classList.add("enabled");
        }

        if (!selectedAdded) {
          for (let eachNode of nodes) {
            let currNode = document.getElementById(eachNode.key);
            currNode.classList.remove("enabled"); 
          }
        }

       
    if (id === "_solve") {
          
        let componentNames = [];
        let componentList;
          for (let i = 0; i < componentsOnBreadboard.length; i++) {
            let currComponentId = componentsOnBreadboard[i][2];
            let componentValue = componentsOnBreadboard[i][6];
            let componentProperties = componentNodeMap.get(currComponentId);
            if (componentProperties.length > 2) {
              componentProperties[2] = componentValue;
            } else {
              componentProperties.push(componentValue);
            }
            
            componentNodeMap.set(currComponentId, componentProperties);
            setComponentNodeMap(componentNodeMap);
            componentNames.push(currComponentId);
          }
        
          componentList = componentNodeMap;
      let hw1 = new breadboard();
      let requiv = hw1.buildBreadBoard(componentNames, componentList);
      setCurrAnswer(()=>requiv);
      removeClass(id, 'selected');
        
    }
    if (id === "_remove") {
      window.location.reload();
    }
        
        
  }     
 
  const selectedOnGraphComponent = (id, value) => {
    selectedGraphComponentName = id;
    selectedGraphComponentValue = value;
    setName(selectedGraphComponentName);
    setValue(selectedGraphComponentValue);

    for (const elem of componentsOnBreadboard) {
      if (elem[2] === selectedGraphComponentName) {
        elem[6] = selectedGraphComponentValue;
        setComponents(() => componentsOnBreadboard);
        break;
      }
    
    }
 
    if (getSelectedComponent() === "_remove") {
      deleteOnGraphComponent(id);
    }

  }

  const deleteOnGraphComponent = (id) => {
   
    let [n1Value, n2Value] = componentNodeMap.get(id);
      let node1;
      let node2;
      nodeMap.forEach((value, key) => {
        if (value === n1Value) {
        node1 = key;
        }
        else if (value === n2Value) {
        node2 = key;
        }
        if (node1 !== undefined && node2 !== undefined) {
        return;
        }
      })
    
      numNodeConnected.set(node1, numNodeConnected.get(node1) - 1);
      numNodeConnected.set(node2, numNodeConnected.get(node2) - 1);
      selectedNode(node1, false);
      selectedNode(node2, false);
      setNumNodeConnected(numNodeConnected);

      componentNodeMap.delete(id);
      setComponentNodeMap(componentNodeMap);
     let newSet = []
    for (let i = 0; i < componentsOnBreadboard.length; i++) {
        if (componentsOnBreadboard[i][2] !== id) {
            newSet.push(componentsOnBreadboard[i]);
          }
      }
    
    setComponents(newSet);
        
  }
  const toggleClass = (id, givenClassName) =>{ 
    let selected = document.getElementById(id);
    if (selected.classList.contains(givenClassName)) {
      selected.classList.remove(givenClassName);
      return false;
    }
    else {
      selected.classList.add(givenClassName);
      return true;
    }
  }
  const addClass = (id, givenClassName) =>{ 
    let selected = document.getElementById(id);
      selected.classList.add(givenClassName);
  }
  const removeClass = (id, givenClassName) =>{ 
    let selected = document.getElementById(id);
      selected.classList.remove(givenClassName)
  }
  const isSelected = (id)=>{
    let object = document.getElementById(id);
    return object.classList.contains("selected");
  }
  const isConnected = (id) => {
    
    let object = document.getElementById(id);
    let connected = object.classList.contains("connected");
    return connected;
  }
   


  return (
    <div className="App">
      <NavBar/>
      <Header />
      <AssemblyTable
        onSelect={select}
        iconId={iconId}
        dashboardIcons={dashboardIcons}
        onBreadBoardComponents={numOfBreadBoardComponents}
        componentsOnBreadboard={componentsOnBreadboard}
        nodes={nodes}
        iconMap={iconMap}
        nodePair={nodePair}
        selectedOnGraphComponent={selectedOnGraphComponent}
        selectedGraphComponentName={selectedGraphComponentName}
        selectedGraphComponentValue={selectedGraphComponentValue}
        logInPageOpen={logInPageOpen}
        userAccount={userAccount}
        setUserAccount={setUserAccount}
        answer={currAnswer}
      />
      
      
    </div>
  )
}

export default App;
