import React from 'react'

function NavBar() {
  return (
      <div className='Navigation'>
          <h1> <a href="/">NortonThevenin</a></h1>
      </div>
  )
}

export default NavBar