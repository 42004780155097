import React from 'react'

function PrivacyPolicy() {
  return (
      <div className='PrivacyPolicies'>
          <a href='/PrivacyPolicy.html' target="_blank">Privacy Policy</a>
      </div>
  )
}

export default PrivacyPolicy