import React from 'react'

const Component = ({id,givenIcon, onSelect}) => {
 
    return (
        <div className='component' id={id} onClick={()=>onSelect(id)}> 
          <div className='componentIcon' >
              {givenIcon}
          </div>
      </div>    
  )
}
export default Component