import React from 'react'
import Components from './Components'

const CDashboard = ({ iconId, componentIcons, onSelect}) => {
  return (
    <div className='cdashboard'>
      <Components iconId={iconId} icons={componentIcons} onSelect={onSelect} />
    </div>
  )
}

export default CDashboard