import React from 'react'

const Node = ({id, onSelect, nodeRef}) => {
  return (
    <div className="Node" ref={nodeRef} id={id} onClick={() => onSelect(id)}>
    </div>
  )
}


export default Node