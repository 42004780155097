import React, { useState,useRef } from 'react'


const Details = ({ selectedGraphComponentName, selectedGraphComponentValue, selectedOnGraphComponent }) => {
  const [value, setValue] = useState(selectedGraphComponentValue);
  const multSelector = useRef(null);
  if (value != selectedGraphComponentValue) {
    setValue(()=>selectedGraphComponentValue);
  }
    
  const updateValue = (e) => {
    e.preventDefault();
    let multiplier = 1;
    let newValue = e.target.value * Number(multiplier);
    setValue(newValue);
    return newValue;
  }

  return (
    <div className='Details'>
      <p>Name: {selectedGraphComponentName}</p>
      <p>Value:</p>
      <form onSubmit={updateValue}>
        <input
          className='displayValue'
          type="number"
          value={value}
          onChange={(e) => {
            selectedOnGraphComponent(selectedGraphComponentName, updateValue(e));
            }
          }
          />
        </form>
    </div>
  )
}


export default Details