import React, { useState, useEffect, useCallback } from 'react'



const Graph = ({nodes, nodePair, nodeRef, iconMap, componentsOnBreadboard, onBreadBoardComponents, wireLength, selectedOnGraphComponent}) => {
  
  let display = []; 

  for (let i = 0; i < componentsOnBreadboard.length; i++){
    let [componentsTypeId, componentStyle, currComponentId, componentWireStyle,componentLeftWireStyle,componentRightWireStyle, componentValue] = componentsOnBreadboard[i];
    let Wire = <div  key={currComponentId+"1"} style={componentWireStyle}></div>;
    let leftWire = <div  key={"left" + currComponentId} style={componentLeftWireStyle} onClick={() => selectedOnGraphComponent(currComponentId,componentValue)}></div>
    let rightWire = <div key={"right" + currComponentId} style={componentRightWireStyle} onClick={() => selectedOnGraphComponent(currComponentId, componentValue)}></div>
    let componentElem = <div className='singleComponentOnBreadBoard' style={componentStyle} key={currComponentId} id={currComponentId} onClick={() => {selectedOnGraphComponent(currComponentId, componentValue)}}>{iconMap.get(componentsTypeId)} </div>;
    if (currComponentId[0] == 'L') {
      display.push( [Wire] );
    }
    else {
      display.push([rightWire,leftWire,componentElem]);
    }
    
  }

  useEffect(() => {    
  })
 
  return (
    <div className='Graph' id='breadBoard'>
      {nodes} 
      <div className='componentsOnBreadBoard'> 
        {display}
      </div>
 
     
    </div>
  )
}

export default Graph