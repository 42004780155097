import React from 'react'

const AnswerPage = ({answer}) => {
    return (
      
    <div className='adashboard'>
        <div>
            The equivalent Resistance of your circuit is: 
        </div>
        <div>
            <p>R-equivalent : <span color='red'>{answer}</span></p>
        </div>
    </div>
  )
}

export default AnswerPage