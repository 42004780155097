import React,{useState} from 'react'
import Breadboard from "./Breadboard";
import CDashboard from "./CDashboard";
import UserAccount from "./UserAccount";
import AnswerPage from './AnswerPage';
import AdvertComponent from "./AdvertComponent";
import PrivacyPolicy from './PrivacyPolicy';
import ArticleComponent from './ArticleComponent';

const AssemblyTable = ({iconId, answer, userAccount, setUserAccount, logInPageOpen, dashboardIcons,iconMap,onSelect , nodePair,onBreadBoardComponents, componentsOnBreadboard , nodes,selectedOnGraphComponent, selectedGraphComponentName,selectedGraphComponentValue}) => {

  
  return (
    <div className='assemblyTable'>
       <AdvertComponent
        width={`${800}px`}
        height={`${20}px`} />
      <Breadboard
        onSelect={onSelect} // a node is selected on the breadboard
        icons={dashboardIcons}
        iconId={iconId}
        onBreadBoardComponents={onBreadBoardComponents}
        nodes={nodes}
        iconMap={iconMap}
        componentsOnBreadboard={componentsOnBreadboard}
        nodePair={nodePair}
        selectedOnGraphComponent={selectedOnGraphComponent}
        selectedGraphComponentName={selectedGraphComponentName}
        selectedGraphComponentValue={selectedGraphComponentValue}
      />
     
      <CDashboard
        iconId={iconId}
        componentIcons={dashboardIcons}
        iconMap={iconMap}
        onSelect={onSelect}
      />
      
      {logInPageOpen && <UserAccount signedIn={logInPageOpen} userAccount={userAccount} setUserAccount={setUserAccount} />}
      <AnswerPage answer={answer} />
      <AdvertComponent
        width={`${93}%`}
        height={`${100}px`} />
      <ArticleComponent/>
      <PrivacyPolicy/>
    </div>
  )
}

export default AssemblyTable