import React from 'react'

const UserAccount = ({signedIn, userAccount , setUserAccount}) => {
  const hasAccount = false;
  
  const onChangeUserName = (e) => {
    const updatedUser = {
      username: e.target.value,
      password: userAccount.password,
      email: userAccount.email
    }
    setUserAccount(() => updatedUser);
  }

  const onChangeUserPass = (e) => {
    const updatedUser = {
      username: userAccount.username,
      password: e.target.value,
      email: userAccount.email
    }
    setUserAccount(() => updatedUser);
  }
  
  const onChangeUserEmail = (e) => {
    const updatedUser = {
      username: userAccount.username,
      password: userAccount.password,
      email: e.target.value
    }
    setUserAccount(() => updatedUser);
  }

  const updateAccount = (e) => {
    e.preventDefault();
  }

  const form = [];
    if (hasAccount) {
      form.push(<input id='username' className='userInput' type='text' placeholder='Username' onChange={(e) => onChangeUserName(e)} value={userAccount.username} />);
      form.push(<input id='password' className='userInput' type='password' placeholder='Password' onChange={(e) => { onChangeUserPass(e)}} value={userAccount.password}  />);
      form.push(<input id='submit' className='userInput s-btn' type='submit' value='Sign in' />);
      form.push(<input id='useGoogle' className='userInput btn' type="button" value='Sign in with Google' />);
    }
    else {
      form.push(<input id='setUserName' className='userInput' type='text' placeholder='Username' onChange={(e) => onChangeUserName(e)} value={userAccount.username} />);
      form.push(<input id='setEmail' className='userInput' type='text' placeholder='Email Address' onChange={(e) => onChangeUserEmail(e)} value={userAccount.email} />);
      form.push(<input id='setPass' className='userInput' type='password' placeholder='Password' onChange={(e) => onChangeUserPass(e)} value={userAccount.password} />);
      // form.push(<input className='userInput' type='password' placeholder='Confirm Password'/>);
      form.push(<input id='submit'className='userInput s-btn' type='submit' value='Sign Up' />);
      form.push(<input id='useGoogle' className='userInput btn' type="button" value='Sign Up with Google' />);
    }
  return (
      <div className='userAccount'>
          <div className='userHeader'>
            Login
          </div>
          <form className='userForm' onSubmit={updateAccount}>
              {form}
          </form>
          <div className='userFooter'>
            <p>Forgot your Password?</p>
            <p>Don't have an account?Create account</p>
          </div>
    </div>
  )
}


export default UserAccount