import * as React from "react";
const SvgWire = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22.334 22.334"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <g
      style={{
        strokeWidth: 1,
      }}
    >
      <path
        style={{
          fill: "none",
          stroke: "none",
          strokeWidth: 3.5,
          strokeLinecap: "round",
          InkscapeStroke: "none",
          stopColor: "#000",
        }}
        d="M290.554 84.414h84.414v84.414h-84.414z"
        transform="matrix(.26458 0 0 .26458 -76.876 -22.334)"
      />
      <path d="M1.32 10.704a.375.463 0 0 0-.375.463.375.463 0 0 0 .375.463h19.694a.375.463 0 0 0 .376-.463.375.463 0 0 0-.376-.463z" />
    </g>
  </svg>
);
export default SvgWire;
