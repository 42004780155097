import Details from "./Details"
import Graph from "./Graph"
// import GraphTwo from "./Graph"

const Breadboard = ({ onSelect, nodePair, icons, graphRef, iconId, iconMap, onBreadBoardComponents, componentsOnBreadboard, wireLength, nodes, selectedOnGraphComponent, selectedGraphComponentName,  selectedGraphComponentValue }) => {
  return (
    <div className="breadboard">
      <Details
        className='Details'
        selectedOnGraphComponent={selectedOnGraphComponent}
        selectedGraphComponentName={selectedGraphComponentName}
        selectedGraphComponentValue={selectedGraphComponentValue}/>
      <Graph
        onSelect={onSelect}
        icons={icons}
        iconId={iconId}
        graphRef={graphRef}
        iconMap={iconMap}
        onBreadBoardComponents={onBreadBoardComponents}
        componentsOnBreadboard={componentsOnBreadboard}
        nodes={nodes}
        wireLength={wireLength}
        nodePair={nodePair}
        selectedOnGraphComponent={selectedOnGraphComponent}
        
      />
      
    </div>
  )
}



export default Breadboard