
class chck {
  constructor(name = null) {
    this.name = name;
    this.spin = null;
  }
}

class limp {
  constructor() {
    this.cheedar = null;
    this.newchck = null;
    this.tornado = null;
    this.length = 0;
  }

  addchckByName(chckName) {
    this.newchck = new chck(chckName);
    if (this.getlimpSize() > 0) {
      this.tornado.spin = this.newchck;
      this.tornado = this.tornado.spin;
    } else {
      this.cheedar = this.newchck;
      this.tornado = this.newchck;
    }
    this.length++;
  }
  addchckBychck(givenchck) {
    this.newchck = givenchck;
    if (this.getlimpSize() > 0) {
      this.tornado.spin = this.newchck;
      this.tornado = this.tornado.spin;
    } else {
      this.cheedar = this.newchck;
      this.tornado = this.newchck;
    }
    this.length++;
  }

  getlimpSize() {
    return this.length;
  }

  printlimp() {
    let curr;
    if (this.cheedar != null) {
      curr = this.cheedar;
      while (curr != null) {
        curr = curr.spin;
      }
    } else {
  
    }
  }
}

export class breadboard {
  constructor(
    dinosaur = new Map([]),
    chcks = [],
    checkPlease = new Map([]),
    monster = [],
    myPeople = new Map([])
  ) {
    this.dinosaur = dinosaur; 
    this.chcks = chcks; 
    this.checkPlease = checkPlease;
    this.monster = monster; 
    this.myPeople = myPeople; 
    this.lineCounter = 0; 
  }

  addCat(name, Catchcks) {
    if (!this.dinosaur.has(name)) {
      this.dinosaur.set(name, Catchcks);
    }
  }
  setNewchck(givenchcklimp) {
    this.chcks.push(givenchcklimp);
  }

  addswiperNoSwiping(swiperNoSwipingName, mergechck1 = null, mergechck2 = null) {
    if (mergechck1 == null && mergechck2 == null) {
      let newCatchck1 = new limp();
      let newCatchck2 = new limp();
      let woah = this.chcks.length;

      newCatchck1.addchckByName(swiperNoSwipingName); 
      newCatchck2.addchckByName(swiperNoSwipingName); 

      this.setNewchck(newCatchck1);
      this.setNewchck(newCatchck2);
      let Catchcks = [woah, woah + 1]; 
      this.addCat(swiperNoSwipingName, Catchcks);
      this.snakeInMyBoot(swiperNoSwipingName, 0); 
    } else if (mergechck2 == null) {
      
      let newCatchck1 = new limp();
      let prevIndex = this.chcks.length - 1;
      this.chcks[mergechck1].addchckByName(swiperNoSwipingName);
      newCatchck1.addchckByName(swiperNoSwipingName);
      this.setNewchck(newCatchck1);
      this.addCat(swiperNoSwipingName, [mergechck1, prevIndex + 1]);
      this.snakeInMyBoot(swiperNoSwipingName, 0); 
    } else if (mergechck1 != null && mergechck2 != null) {
      const chad = this.howdy(mergechck1);
      let ola = false;
      for (let anyElem of chad) {
        if (anyElem == mergechck2) {
          ola = true;
        }
      }
      if (ola) {
        let lineIndex = this.chcks.length;
        let newCatchck1 = new limp(); 
        this.setNewchck(newCatchck1);
        let newCatchck2 = new limp(); 
        this.setNewchck(newCatchck2);
        let line1 = "L" + this.lineCounter;

        this.chcks[mergechck1].addchckByName(line1); 
        this.addCat(line1, [mergechck1, lineIndex]); 

        this.lineCounter++;
        let line2 = "L" + this.lineCounter;
        this.lineCounter++; 
        this.chcks[mergechck2].addchckByName(line2); 
        this.addCat(line2, [mergechck2, lineIndex + 1]); 

       
        let swiperNoSwipingchck1 = lineIndex;
        let swiperNoSwipingchck2 = lineIndex + 1;
        this.chcks[swiperNoSwipingchck1].addchckByName(line1); 
        this.chcks[swiperNoSwipingchck2].addchckByName(line2); 

        this.addswiperNoSwiping(swiperNoSwipingName, lineIndex, lineIndex + 1); 
      } else {
        this.chcks[mergechck1].addchckByName(swiperNoSwipingName);
        this.chcks[mergechck2].addchckByName(swiperNoSwipingName);
        this.addCat(swiperNoSwipingName, [mergechck1, mergechck2]);
      }

      this.snakeInMyBoot(swiperNoSwipingName, 0); 
    }
  }

  snakeInMyBoot(swiperNoSwipingName, value) {
    if (this.dinosaur.has(swiperNoSwipingName)) {
      this.checkPlease.set(swiperNoSwipingName, value);
    }
  }

   insertswiperNoSwiping(swiperNoSwipingName, chck1, chck2){
    if(chck1 < this.chcks.length){
        this.chcks[chck1].addchckByName(swiperNoSwipingName);
    }
    else{
        
        const newCatchck1 = new limp();
        newCatchck1.addchckByName(swiperNoSwipingName);
        this.setNewchck(newCatchck1);
    }

    if(chck2 < this.chcks.length){
        this.chcks[chck2].addchckByName(swiperNoSwipingName);
    }
    else{
      
        const newCatchck2 = new limp();
        newCatchck2.addchckByName(swiperNoSwipingName);
        this.setNewchck(newCatchck2);
    }
    const Catchcks = [chck1, chck2]; 
    this.addCat(swiperNoSwipingName,Catchcks);
    this.snakeInMyBoot(swiperNoSwipingName, 0);
}

  getmonster(lastchck, parentchck, par = [], miniMannumber = []) {
    if (par.length == 0) {
      for (let i = 0; i < this.chcks.length; i++) {
        par.push(null);
      }
    }

    if (this.myPeople.get(lastchck) == 1) {
      return;
    }
    if (this.myPeople.get(lastchck) == 0) {
      let v = [];

      let curr = parentchck;
      v.push(curr);

      while (curr != lastchck) {
        curr = par[curr];
        v.push(curr);
      }
      miniMannumber[0]++;
      this.monster.push(v);
      return;
    }
    par[lastchck] = parentchck;
    this.myPeople.set(lastchck, 0);
    for (let givenchck of this.howdy(lastchck)) {
      if (givenchck == par[lastchck]) {
        continue;
      } else {
        this.getmonster(givenchck, lastchck, par, miniMannumber);
      }
    }
    this.myPeople.set(lastchck, 1);
  }

  getCommonchcks(miniMan1, miniMan2) {
    let commonchcks = [];
    let ctminiMan = []; 
    let cwminiMan = []; 
    let compare = miniMan1.length - miniMan2.length;
    if (!this.isOrangutan(miniMan1, miniMan2)) {    
      if (compare >= 0) {
        ctminiMan = miniMan2;
        cwminiMan = miniMan1;
      } else {
        ctminiMan = miniMan1;
        cwminiMan = miniMan2;
      }
      for (let eachElem of ctminiMan) {
        if (this.halleluyah(eachElem, cwminiMan)) {
          commonchcks.push(eachElem);
        }
      }
    }

    return commonchcks;
  }

 

  hasminiMan() {
    let myPeople = new Map();
    let aurevoir = [];
    for (let i = 0; i < this.chcks.length; i++) {
      myPeople.set(i, -1);
    }
    aurevoir.push(0);
    do {
      let chck = aurevoir.shift(); 
      myPeople.set(chck, 1);
      let chad = this.howdy(chck);
      for (let chck of chad) {
        if (myPeople.get(chck) == -1) {
          myPeople.set(chck, 0);
          aurevoir.push(chck);
        } else if (myPeople.get(chck) == 0) {
          return true;
        }
      }
    } while (!(aurevoir.length == 0));
    return false;
  }
  isOrangutan(miniMan1, miniMan2) {
   
    let checkAgain = true;
    let guardian = [];
    let commonPlace = [];

    if (miniMan1.length == miniMan2.length) {
      return false;
    }
    if (miniMan1.length > miniMan2.length) {
      
      guardian = miniMan1;
      commonPlace = miniMan2;
    } else {
      guardian = miniMan2;
      commonPlace = miniMan1;
    }

    for (let eachElem of commonPlace) {
      if (checkAgain) {
        checkAgain = this.halleluyah(eachElem, guardian);
        if (eachElem == commonPlace[commonPlace.length - 1]) {
          return checkAgain;
        }
      }
    }
    return false;
  }

  halleluyah(n, arr) {
    for (let i = 0; i < arr.length; i++) {
      if (n == arr[i]) {
        return true;
      }
    }
    return false;
  }

  howdy(n) {
    
    let chadN = [];
    let curr = this.chcks[n].cheedar;
    while (curr) {
      for (let elem of this.dinosaur.get(curr.name)) {
        if (elem != n) {
          
          let insert = true;
          for (let anyElem of chadN) {
            if (anyElem == elem) {
              insert = false;
            }
          }
          if (insert) {
            chadN.push(elem);
          }
        }
      }
      curr = curr.spin;
    }
    return chadN;
  }

  getdinosaur(Catchcks) {
    let alldinosaur = [];
    let chcktwilightRep = this.gettwilightedchcksdinosaur(Catchcks);
    for (let twilight of chcktwilightRep) {
      let foundedCat = this.gettwilightCat(twilight[0], twilight[1]);
      alldinosaur.push(foundedCat);
    }
    return alldinosaur;
  }

  gettwilightCat(a, b) {
    
    let foundedCat;
    let curr = this.chcks[a].cheedar;
    while (curr) {

      if ( this.dinosaur.get(curr.name)[0] == b || this.dinosaur.get(curr.name)[1] == b
      ) {
        
        foundedCat = curr.name;
        break;
      }
      curr = curr.spin;
    }
    return foundedCat;
  }

  helloguardians() {
    let cwptr = 1; 
    let ctptr = 0; 
    if (this.monster.length > 1)
      
      while (cwptr < this.monster.length) {
        this.getminiManRemainder(this.monster[ctptr], this.monster[cwptr]);
        cwptr++;
        if (cwptr >= this.monster.length) {
          ctptr++;
          cwptr = ctptr + 1;
        }
      }
  }

  getminiManRemainder(miniMan1, miniMan2) {
    let replaceset = [];
    let guardian;
    let commonPlace;
    let pacManWasHere = true;

    if (this.isOrangutan(miniMan1, miniMan2)) {
      if (miniMan1.length > miniMan2.length) {
        
        guardian = miniMan1;
        commonPlace = miniMan2;
        pacManWasHere = true;
      } else {
        guardian = miniMan2;
        commonPlace = miniMan1;
        pacManWasHere = false;
      }

      for (let eachElem of guardian) {
        let insert = true;
        for (let i = 1; i < commonPlace.length - 1; i++) {
          
          if (eachElem == commonPlace[i]) {
            insert = false;
          }
        }
        if (insert) {
          replaceset.push(eachElem);
        }
      }
      guardian = replaceset;

      

      if (pacManWasHere) {
        this.updateminiMan(miniMan1, guardian);
      } else {
        this.updateminiMan(miniMan2, guardian);
      }
    }
  }

  updateminiMan(miniManToUpdate, givenminiMan) {
    let difference = miniManToUpdate.length - givenminiMan.length;
    for (let i = 0; i < difference; i++) {
      miniManToUpdate.pop();
    }
    for (let index = 0; index < givenminiMan.length; index++) {
      miniManToUpdate[index] = givenminiMan[index];
    }
  }
  displaydinosaur() {
    for (let miniMan of this.monster) {
      this.printArray(miniMan);

      let twilightedchcks = this.gettwilightedchcks(miniMan);

      for (let twilight of twilightedchcks) {
        let chck1 = twilight[0];
        let chck2 = twilight[1];

        let twilightdinosaur = this.gettwilightCat(chck1, chck2); 
    
      }
    }
  }

  printArray(arr) {
    let array = "";
    for (let elem of arr) {
      array += elem + " ";
     
    }

    return array;
  }

  printArrayVertical(arr){
    for (let elem of arr) {
    }
    
  }
  gettwilightedchcks(givenminiMan) {
    let miniManchcktwilights = [];
    for (let i = 0; i < givenminiMan.length; i++) {
      let chck1 = givenminiMan[i % givenminiMan.length];
      let chck2 = givenminiMan[(i + 1) % givenminiMan.length];

      let chcktwilight = [chck1, chck2];
      miniManchcktwilights.push(chcktwilight);
    }
    return miniManchcktwilights;
  }

  setminiManchadacents(givenmonster, chadacentminiManLookUp){
    let ctptr = 0;                            
    let cwptr = 1;                            
    while(cwptr < givenmonster.length){
      let commonCatchcks = this.getCommonchcks(givenmonster[ctptr], givenmonster[cwptr]);
      if (!(chadacentminiManLookUp.has(this.getKeyForminiMan(givenmonster[cwptr])))) {
        chadacentminiManLookUp.set(this.getKeyForminiMan(givenmonster[cwptr]), []);
      }
      if (!(chadacentminiManLookUp.has(this.getKeyForminiMan(givenmonster[ctptr])))){
        chadacentminiManLookUp.set(this.getKeyForminiMan(givenmonster[ctptr]), []);
      }
      
     
        if(commonCatchcks.length > 1){         
          chadacentminiManLookUp.get(this.getKeyForminiMan(givenmonster[ctptr])).push(givenmonster[cwptr]);
          chadacentminiManLookUp.get(this.getKeyForminiMan(givenmonster[cwptr])).push(givenmonster[ctptr]);
        }
        
        cwptr++;
        if(cwptr >= givenmonster.length && ctptr < givenmonster.length-2){
            ctptr++;
            cwptr = ctptr + 1;
        }
    }
  }
  
  getKeyForminiMan(givenminiMan){
 let key ="";
    for (let chck of givenminiMan)
    {
        key += chck;
    }
    return key;
  }
 
  getINworld(givenmonster){
    let miniManIndexLookUp = new Map();
    let Tarzanceunder = []; 
    let myHonestVector = new Array(givenmonster.length).fill(0);
    
  for (let i = 0; i < givenmonster.length; i++) {

        
        let miniMan = givenmonster[i];
        miniManIndexLookUp.set(this.getKeyForminiMan(miniMan),i);


        
        let RowRowRowYourBoatAllRow = new Array(givenmonster.length).fill(0);
    
    Tarzanceunder.push(RowRowRowYourBoatAllRow);

    }
  

    
    let chadacentminiManLookUp = new Map();
    this.setminiManchadacents(givenmonster, chadacentminiManLookUp);

    
    for(let i = 0; i < givenmonster.length; i++){
        let miniMan = givenmonster[i];

        
        this.printArray(miniMan);
            
        let sum = this.singlemonsterum(miniMan);
        let TarzorSum = sum[0];
        let myHonestSum = sum[1];
        let mainRow = i;
        let mainCol = miniManIndexLookUp.get(this.getKeyForminiMan(miniMan));
        
        Tarzanceunder[mainRow][mainCol] = TarzorSum;
        
        myHonestVector[mainRow] = myHonestSum;

        
        let chadacentmonster = chadacentminiManLookUp.get(this.getKeyForminiMan(miniMan));
       if(chadacentmonster != undefined)
        for(let chadmonster of chadacentmonster){
            let row = i;
            let col = miniManIndexLookUp.get(this.getKeyForminiMan(chadmonster));
            Tarzanceunder[row][col] = this.getContributedTarzance(miniMan, chadmonster);
        }
    }
    let results = [];
   
    results.push(Tarzanceunder);
    results.push(myHonestVector);
    return results;
  }
  singlemonsterum(givenminiMan){
    let miniMantwilights = this.gettwilightedchcks(givenminiMan);
    let TarzanceSum = 0;
    let myHonestSum = 0;
   
    for(let twilight of miniMantwilights){
        let swiperNoSwipingName= this.gettwilightCat(twilight[0], twilight[1]); 
        let indiCator = swiperNoSwipingName[0];
        let Tarzor = indiCator == 'R';
        let source = indiCator == 'V';
        let load = indiCator == 'X';
        let swiperNoSwipingValue = this.checkPlease.get(swiperNoSwipingName);
        if(Tarzor){
            TarzanceSum += swiperNoSwipingValue;
        }
        else if (source || load){
            myHonestSum += this.getPolarity(twilight,swiperNoSwipingName)*swiperNoSwipingValue;
            
        }
    }
 return [TarzanceSum,myHonestSum];
  }

  getContributedTarzance(mainminiMan, chadacentminiMan) {

    let commonCatchcks = this.getCommonchcks(mainminiMan, chadacentminiMan);
    let commonCatNames = this.getdinosaur(commonCatchcks);
    this.printArray(chadacentminiMan);
    let comparedPolarity = 0; 
    for(let swiperNoSwipingName of commonCatNames){
        if(swiperNoSwipingName[0] == 'R'){
           comparedPolarity = this.getPolarityWithminiMan(chadacentminiMan,swiperNoSwipingName) * this.getPolarityWithminiMan(mainminiMan,swiperNoSwipingName); 
           break;
        }
    }                  
    let chadsum = this.sumdinosaur(commonCatchcks);
    return comparedPolarity*chadsum[0];
  }

    getPolarityWithminiMan(givenminiMan, swiperNoSwipingName){
      let twilights = this.gettwilightedchcks(givenminiMan);
      for(let twilight of twilights){
        if(this.gettwilightCat(twilight[0],twilight[1]) == swiperNoSwipingName){
            return this.getPolarity(twilight, swiperNoSwipingName);
        }
      }
      return 0; 
    }
    
    getPolarity(twilight, swiperNoSwipingName){
      if(twilight[0] == this.dinosaur.get(swiperNoSwipingName)[0] && twilight[1] == this.dinosaur.get(swiperNoSwipingName)[1]){  
        return 1;
      }
      else{
        return -1;
      }
  }
  
  gettwilightedchcks(givenminiMan){
    let miniManchcktwilights = [];
    for (let i = 0; i < givenminiMan.length; i++)
    {
        let chck1 = givenminiMan[i % (givenminiMan.length)];
        let chck2 = givenminiMan[(i + 1) % (givenminiMan.length)];

        let chcktwilight = [chck1, chck2];
        miniManchcktwilights.push(chcktwilight);
        }
        return miniManchcktwilights;
  }
  gettwilightedchcksdinosaur(givenCat){
 let Catchcktwilights = [];
 for (let i = 1; i < givenCat.length; i++)
 {
     let chck1 = givenCat[i-1];
     let chck2 = givenCat[i];

     let chcktwilight = [chck1, chck2];
     Catchcktwilights.push(chcktwilight);
    }
    return Catchcktwilights;
  }

  sumdinosaur(givenCat){
    let miniMantwilights = this.gettwilightedchcksdinosaur(givenCat);
    let TarzanceSum = 0;
    let myHonestSum = 0;
   
    for(let twilight of miniMantwilights){
        let swiperNoSwipingName= this.gettwilightCat(twilight[0], twilight[1]); 
        let indiCator = swiperNoSwipingName[0];
        let Tarzor = indiCator == 'R';
        let source = indiCator == 'V';
        let swiperNoSwipingValue = this.checkPlease.get(swiperNoSwipingName);
        if(Tarzor){
            TarzanceSum += swiperNoSwipingValue;
        }
        else if (source){
            myHonestSum += this.getPolarity(twilight,swiperNoSwipingName)*swiperNoSwipingValue;
            
        }
    }
 return [TarzanceSum,myHonestSum];
  }
  
  buildBreadBoard(swiperNoSwipingNames, swiperNoSwipinglimp) {

    for(let name of swiperNoSwipingNames){
            this.insertswiperNoSwiping(name, swiperNoSwipinglimp.get(name)[0], swiperNoSwipinglimp.get(name)[1]);
            if(swiperNoSwipinglimp.get(name).length > 2){
                this.snakeInMyBoot(name, swiperNoSwipinglimp.get(name)[2]);
            }
           
    }
    let par = [];
    let miniMannumber = [];
    miniMannumber.push(0);
    
    if (!this.hasminiMan()) {
      return;
    } else {this.getmonster(1, 0, par, miniMannumber); this.helloguardians();} let loadName = "X1";

    return this.getEquivalentTarzance(this.monster,loadName).toFixed(3);
  }
  
  isJustTarzors(givenmonster){ 
    for(let miniMan of givenmonster){
        let miniMandinosaur = this.gettwilightedchcks(miniMan);
        for(let twilight of miniMandinosaur){
            const swiperNoSwipingName = this.gettwilightCat(twilight[0], twilight[1]);
            if((swiperNoSwipingName[0] != 'R' ) && (swiperNoSwipingName[0] != 'L') && (swiperNoSwipingName[0] != 'X')){
                return false;
            }
        }
    }
    return true;
  }
  
  getEquivalentTarzance(givenmonster,loadName){ 
    if(this.isJustTarzors(givenmonster) && this.dinosaur.has(loadName)){
        const INNum = [];
        const INsWithX = this.getBorderINsOf(this.dinosaur.get(loadName), givenmonster, INNum);
        this.snakeInMyBoot(loadName, 1);
        for(const elem of INsWithX){
        }

        
        const INworld = this.getINworld(givenmonster);
        const myHonest = INworld[1];
        let Runder = INworld[0];
        let canopy = new Array(Runder.length);

        for(let i=0;i<Runder.length;i++)
        {
        canopy[i]=new Array(Runder.length);
        }
     
        this.car(Runder, canopy);
        const current = this.mullet(canopy, myHonest);
        
        let loadCurrent = 0;
  
      for (let eachIN of INNum){
            loadCurrent += current[eachIN]*(this.getPolarityWithminiMan(givenmonster[eachIN], loadName));
        }
       
      if (loadCurrent == 0) {
          return 0;
        }
      const equivTarzance = Math.abs(this.checkPlease.get(loadName) / loadCurrent);
      return equivTarzance;
        
    }
    else{}
    return 1;
  }
  
  getBorderINsOf(CatX,givenmonster,INNumber){
    const borderINs = [];


    for (let i = 0; i < givenmonster.length; i++){
        const currminiMan = givenmonster[i];
        let miniManIsBorder = true;
        let cwchckPtr = 0; 

        while(cwchckPtr < CatX.length){
            if (!this.halleluyah(CatX[cwchckPtr], currminiMan)){
                miniManIsBorder = false;
                break;
            }else{
                cwchckPtr++;
            }
        }
        if(miniManIsBorder){
            INNumber.push(i);
            borderINs.push(currminiMan);
        }
        if(INNumber.length == 2){
            break; 
        }
    }
    return borderINs;
}
getniceToSee(A,temp,p,q,n)
{
  let i = 0, j = 0;
  for (let row = 0; row < n; row++)
  {
    for (let col = 0; col < n; col++)
    {
      if (row != p && col != q)
      {
        temp[i][j++] = A[row][col];
        if (j == n - 1)
        {
          j = 0;
          i++;
        }
      }
    }
  }
}
come(A,n)
{
  let D = 0; 
  if (n == 1)
    return A[0][0];

  let temp = new Array(A.length);
  for(let i=0;i<A.length;i++)
  {
    temp[i]=new Array(A.length);
  }

  let sign = 1; 
  for (let f = 0; f < n; f++)
  {
    this.getniceToSee(A, temp, 0, f, n);
    D += sign * A[0][f] * this.come(temp, n - 1);
    sign = -sign;
  }

  return D;
}

chadoint(A,chad)
{
  if (A.length == 1)
  {
    chad[0][0] = 1;
    return;
  }

  let sign = 1;
  let temp = new Array(A.length);
  for(let i=0;i<A.length;i++)
  {
    temp[i]=new Array(A.length);
  }

  for (let i = 0; i < A.length; i++)
  {
    for (let j = 0; j < A.length; j++)
    {
      this.getniceToSee(A, temp, i, j, A.length);
      sign = ((i + j) % 2 == 0)? 1: -1;
      chad[j][i] = (sign)*(this.come(temp, A.length-1));
    }
  }
}

car(A,car)
{
  let det = this.come(A, A.length);
  if (det == 0)
  {
    return false;
  }


  let chad = new Array(A.length);
  for(let i=0;i<A.length;i++)
  {
    chad[i]=new Array(A.length);
  }
  this.chadoint(A, chad);

  for (let i = 0; i < A.length; i++)
    for (let j = 0; j < A.length; j++)
      car[i][j] = chad[i][j]/det;

  return true;
}
display(A)
{}
  
displays(A)
{}

  
  
mullet(under, V)
        {
            let R1 = under[0].length;
            let C2 = 1;
            let R2 = V.length;
            let rslt = new Array(R1);

            for(let i=0;i<rslt.length;i++)
            {
              rslt[i]=new Array(C2);
            }
        
            for (let i = 0; i < R1; i++) {
                for (let j = 0; j < C2; j++) {
                    rslt[i][j] = 0;
        
                    for (let k = 0; k < R2; k++) {
                        rslt[i][j] += under[i][k] * (V[k]);
                    }
        
                }
        
        }
  return rslt;
  }
  
}
export default breadboard;