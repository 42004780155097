import * as React from "react";
const SvgResistor = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22.334 22.334"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path
      d="m238.344 120.213-4.06 8.12-1.246-2.495-1.567-.967h-20.01a1.75 1.75 0 0 0-1.75 1.75 1.75 1.75 0 0 0 1.75 1.75h18.93l2.328 4.657h3.131l4.059-8.12 4.06 8.12h3.131l4.059-8.12 4.06 8.12h3.131l4.059-8.12 1.248 2.495 1.564.968h20.012a1.75 1.75 0 0 0 1.75-1.75 1.75 1.75 0 0 0-1.75-1.75h-18.93l-2.328-4.658h-3.13l-4.061 8.12-4.059-8.12h-3.13l-4.061 8.12-4.059-8.12z"
      transform="matrix(.26458 0 0 .26458 -54.541 -22.334)"
      style={{
        strokeWidth: 1,
      }}
    />
  </svg>
);
export default SvgResistor;
