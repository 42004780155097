import React from 'react'
import Component from './Component'

const Components = ({ iconId, icons, onSelect }) => {
  const components = [];
  for (let i = 0; i < icons.length; i++) {
    let id = iconId[i];
    components.push(<Component key={id} id={id} givenIcon={icons[i]} onSelect={onSelect} />);
  }
  return (
    <div className='components'>
      {components}
    </div>
    
  )
}

export default Components