import React from 'react'

function ArticleComponent() {
    return (
    <div className='articleComponent'>
            <h1>About the ERC calculator</h1>
            
        <h2> What is the ERC calculator</h2>  
        <p>
              The ERC (Equivalent Resistance Circuit) calculator takes in your drawn circuit and gives you the equivalent resistance.
              Currently the calculator only accepts resistor values. Future iterations should include circuits with voltage and current supplies.
        </p>
        <p>
              The goal of the ERC calculator is to help students quickly check their work whenever they are solving these types of problems in school.
              Another use case is for understanding edge cases. The calculator can solve cube shaped circuits and other seemigly 3D shaped circuits (In reality
            these are just 2D circuits that are drawn to look 3D).  
        </p>  
        <h2>How it works</h2>  
        <p>
              To use the calculator please select the component you wish to draw (resistor or wire). Only when you have selected a component would you
              be able to draw on the "breadboard".

              When you have connected all your components, and have assigned values to them, please select the start button.

              For a tutorial please look at the "How to video" in the link.
        </p>  
        <p>
            More updates will be coming soon. If you would like to suggest/report a solution please send an email to enquiries@nortonthevenin.tech.
            </p>  
            <h2>How to manually calculate equivalent resistance</h2>  
        <p>
            To calculate the equivalent resistance of a cicuit you have to seperate groups of components into series and parallel.
            Parallel components share the same voltage while series components share the same current.

        </p>  
        <p>
            The equivalent resistance for special cases like Y and Delta circuits can also be treated using this calculator. 
        </p>  
    </div>
  )
}

export default ArticleComponent