import * as React from "react";
const SvgSolve = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22.334 22.334"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path
      d="M501.588 104.12c-12.431 0-22.5 10.07-22.5 22.5 0 12.432 10.069 22.5 22.5 22.5s22.5-10.068 22.5-22.5c0-12.43-10.069-22.5-22.5-22.5zm-4.5 32.625v-20.25l13.5 10.125z"
      transform="matrix(.26458 0 0 .26458 -121.545 -22.334)"
      style={{
        strokeWidth: 1,
      }}
    />
  </svg>
);
export default SvgSolve;
