import * as React from "react";
const SvgRemove = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22.334 22.334"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path
      d="M670.415 104.12a22.495 22.495 0 0 0-20.787 13.89 22.495 22.495 0 0 0 4.877 24.52 22.502 22.502 0 0 0 31.82 0 22.502 22.502 0 0 0-7.3-36.697 22.495 22.495 0 0 0-8.61-1.713zm7.375 12.16c.352 0 .703.135.972.403l1.555 1.556a1.375 1.375 0 0 1 0 1.944l-6.414 6.415 6.414 6.414a1.375 1.375 0 0 1 0 1.945l-1.555 1.555a1.376 1.376 0 0 1-1.945 0l-6.414-6.415-6.415 6.415a1.374 1.374 0 0 1-1.944 0l-1.556-1.555a1.375 1.375 0 0 1 0-1.945l6.415-6.414-6.415-6.415a1.375 1.375 0 0 1 0-1.944l1.556-1.556a1.37 1.37 0 0 1 .972-.403c.352 0 .704.135.972.403l6.415 6.415 6.414-6.415c.27-.269.621-.403.973-.403z"
      transform="matrix(.26458 0 0 .26458 -166.213 -22.334)"
      style={{
        strokeWidth: 1,
      }}
    />
  </svg>
);
export default SvgRemove;
