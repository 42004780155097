import * as React from "react";
const SvgLoad = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22.334 22.334"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path
      d="M399.674 117.87a1.75 1.75 0 0 0-1.75 1.75v5.25h-17.967a1.419 1.75 0 0 0-.71.235 1.419 1.75 0 0 0-.519.64 1.419 1.75 0 0 0-.19.875 1.419 1.75 0 0 0 .19.875 1.419 1.75 0 0 0 .52.641 1.419 1.75 0 0 0 .709.234h17.967v5.25a1.75 1.75 0 0 0 1.75 1.75h35a1.75 1.75 0 0 0 1.75-1.75v-5.25h17.966a1.419 1.75 0 0 0 .278-.035 1.419 1.75 0 0 0 .265-.1 1.419 1.75 0 0 0 .246-.16 1.419 1.75 0 0 0 .215-.218 1.419 1.75 0 0 0 .176-.266 1.419 1.75 0 0 0 .133-.3 1.419 1.75 0 0 0 .08-.33 1.419 1.75 0 0 0 .027-.34 1.419 1.75 0 0 0-.027-.342 1.419 1.75 0 0 0-.08-.329 1.419 1.75 0 0 0-.133-.302 1.419 1.75 0 0 0-.176-.266 1.419 1.75 0 0 0-.215-.217 1.419 1.75 0 0 0-.246-.162 1.419 1.75 0 0 0-.265-.1 1.419 1.75 0 0 0-.278-.033h-17.966v-5.25a1.75 1.75 0 0 0-1.75-1.75zm1.75 3.5h31.5v10.5h-31.5v-7z"
      transform="matrix(.26458 0 0 .26458 -99.21 -22.334)"
      style={{
        strokeWidth: 1,
      }}
    />
  </svg>
);
export default SvgLoad;
